import React, { Fragment, useEffect, useState } from "react";
import TournamentTitle from "../assets/images/tournament-title.svg";
import {
  newGetFeatureTournamnet,
  checkUserDetails,
  onCreateContest,
  getApplyEventCode,
  getUserInGameName,
  newGetEventByCode,
} from "../apis";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { InGameNameApiCall, dateFormat } from "../component/common";
import url from "../constants/url";
import Loader from "../component/loader";
import Close from "../assets/images/close.svg";
import Rupee from "../assets/images/rupee.svg";
import TournamentsStructure from "../component/tournaments-structure";
import { Link } from "react-router-dom";
import Previous from "../assets/images/previous.svg";
import squadDummy from "../assets/images/squad-dummy.png";
import rightArrow from "../assets/images/right-arrow.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { constants } from "../constants/constants";
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie'
import { useProfile } from "../component/ProfileComponent/ProfileContext";

function Featured(props) {
  const [allData, setAllData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [showContacts, setShowContacts] = useState(false);
  const [showjoinpopup, setShowjoinpopup] = useState(true);
  const [showJoinTeam, setShowJoinTeam] = useState(false);
  const [allFeaturedData, setAllFeaturedData] = useState({});
  const [userData, setUserData] = useState(null);
  const [selectedPlayerData, setSelectedPlayerData] = useState([]);
  const [allTeamsIds, setAllTeamsIds] = useState([]);
  const [uniqueIgn, setUniqueIgn] = useState("");
  const [playerPhone, setPlayerPhone] = useState([]);
  const [selectedContest, setSelectedContest] = useState(null);
  const {t} = useTranslation()
  const {walletDetails} = useProfile()
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (props?.joinpopup === false) {
      showFeatureTounaments(JSON.parse(localStorage.getItem("joinTounament")));
      setShowContacts(true);
      setShowjoinpopup(false);
    }
  }, [props.joinpopup]);

  let findUserName = async() => {
    // let activeGameId = localStorage.getItem("activeGameId");
    // //console.log("activeGameId", activeGameId);
    // let gameNames = JSON.parse(localStorage.getItem("userInGameNames"));
    // // console.log("gameNames", gameNames);
    // if (gameNames) {
    //   let index = gameNames?.findIndex((obj) => obj?.game === activeGameId);
    //   //console.log("index gamename", index);
    //   if (index >= 0) {
    //     //console.log("index gameNames[index].userINGameName", gameNames[index].userINGameName);

    //     // setCurrentName(gameNames[index].userINGameName)
    //     return gameNames[index].userINGameName || "";
    //   } else {
    //     return "";
    //   }
    // }
    // var user_data = JSON.parse(localStorage.getItem(constants.profileLite));
  let payload = {
    "search": {
      "user": walletDetails?.user_id,
      "game": localStorage.getItem('activeGameId')
    }
  }

  let gameNamesData = await getUserInGameName(payload)
  // console.log(gameNamesData,"*****************",gameNamesData?.data?.[0]?.userINGameName)
  return gameNamesData?.data?.[0]?.userINGameName;
    
  };

  const fetchData = async () => {
    setUserData(JSON.parse(localStorage.getItem('profileLite')));
    setLoader(true);
    let data = {
      skip: 0,
      limit: 100,
      filter: {
        isFeatured: true,
        tournamentType: "gamerji",
        user: walletDetails?.user_id,
      },
    };
    let response = await newGetFeatureTournamnet(data);
    if (response) {
      let temp = [];
      response?.data?.length &&
        response?.data?.map(async (item, index) => {
          temp.push({
            allData: item,
            img: url.imageUrl + item?.featuredImage?.default,
            id: item?._id,
            title: item?.title,
            gameName: item?.gameAry?.name,
            gameType: item?.gameTypeAry?.name,
            prizePool: item?.prizePool,
            currency: item?.currency,
            rewardDisplayText: item?.rewardDisplayText
          });
        });
        // console.log(temp[0]?.currency)
      setAllData(temp);
      setLoader(false);
    }
  };

  const showFeatureTounaments = async (data) => {
    // localStorage.setItem("joinTounament", JSON.stringify(data));
    // console.log("Fea: ", data);
    let response = await newGetEventByCode({
      code: data?.allData.shortCode.toUpperCase(),
      user: walletDetails?.user_id,
    });

    localStorage.setItem("activeGame", response?.item?.gameAry?.name);
    localStorage.setItem("tournamentId", response?.item?._id);
    localStorage.setItem("activeGameId", response?.item?.gameAry?._id);
    localStorage.setItem(
      "activeGameType",
      JSON.stringify(response?.item?.gameTypeAry)
    );
    // let ignName =await  findUserName()
    console.log(response?.item?.userInGameName, "*********")
    let temp = {
      
      id: response?.item?._id,
      img: url.imageUrl + response?.item?.featuredImage?.default,
      title: response?.item?.title,
      // contestsList: response?.item?.contestsList, // not present
      totalPlayers: response?.item?.totalPlayers, // not present
      isJoined: response?.item?.isJoined, // not present
      totalJoinedPlayers: response?.item?.totalJoinedPlayers, // not present
      shortCode: response?.item?.shortCode, // not present
      gameName: response?.item?.gameAry?.name,
      gameId: response?.item?.gameAry?._id,
      // levelNote: response?.item?.game?.settings?.levelNote, // not present
      winners: response?.item?.totalWinners, // not present
      gameType: response?.item?.gameTypeAry?.name,
      gameTypePlayers: response?.item?.gameTypeAry?.players, // not present
      gameTypeExtraPlayers: response?.item?.gameTypeAry?.extraPlayers, // not present
      hostedby: response?.item?.host?.name, // not present
      hostrate: response?.item?.host?.rate, // not present
      entryFee: response?.item?.entryFee, // not present
      titles: response?.item?.titles, // not present
      // rules: response?.item?.rules, // not present
      // allrounds: response?.item?.rounds, // not present
      roundsCount: response?.item?.roundsCount, // not present
      startDate: response?.item?.startDate,
      endDate: response?.item?.endDate,
      // prizepool: response?.item?.prizePoolInfo, // not present
      prizePool: response?.item?.prizePool,
      // pointpool: response?.item?.pointpool, // not present
      currency: response?.item?.currency[0],
      // roundDatetime: response?.item?.rounds[0]?.matches, // not present
      inGameName:  response?.item?.userInGameName || await findUserName(),
      // // createdAt: dateFormat(response?.item?.createdAt),
      // // updatedAt: dateFormat(response?.item?.updatedAt), // not present
      rewardDisplayText: response?.item?.rewardDisplayText
    };
    setAllFeaturedData(temp);
    setShowContacts(true);
  };

  useEffect(() => {
    props.showContacts(showContacts);
  }, [showContacts]);

  let colorsOne = ["#FFC609", "#43F2FF", "#09FFC4"];
  const Owloptions = {

  rtl:  Cookies.get('i18next').toLowerCase() == 'ar' ? true : false, // Enable RTL mode
    items: 3,
  }

  return (
    <>
      <ToastContainer />
      {allData?.length > 0 ? (
        <Fragment>
          <h2 className="icon-title">
            <span className="icon">
              <img src={TournamentTitle} alt="" />
            </span>
            { t('home.featured_tournament') }
          </h2>
          {loader ? (
            ""
          ) : (
            <div className="tournament_items">
              <OwlCarousel
                className="owl-carousel owl-theme"
                autoWidth={false}
                items={3}
                loop={false}
                margin={0}
                autoplay={false}
                dots={false}
                nav={true}
                {...Owloptions}
              >
                {allData?.map((item, index) => {
                  return (
                    <div className="item" key={index}>
                      <div
                        className="d-block pointer"
                        onClick={() => showFeatureTounaments(item)}
                      >
                        <div
                          className="box"
                          style={{
                            backgroundColor:
                              colorsOne[index % colorsOne.length],
                          }}
                        >
                          <div className="img">
                            <img src={item.img} alt="" />

                            <div className="img-caption">
                              <div className="marquee-div">
                                <div className="marquee-text">
                                  <h3 className="">
                                    {item.gameName} - {item.gameType}
                                  </h3>
                                </div>
                              </div>
                              <div
                                className={`brdr-arrow-${
                                  index % colorsOne.length
                                }`}
                              ></div>
                              <div className="marquee-div">
                                <div className="marquee-text">
                                  <h6>{item?.title}</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                          {item?.rewardDisplayText != null ?                            
                            <h5 className="text-center coin_featured" style={{textOverflow: "ellipsis", whiteSpace:'nowrap', overflow:'hidden'}}>
                            <span>{item?.rewardDisplayText}</span>
                          </h5>  
                          :
                          <h5 className="text-center coin_featured">
                            {item.currency?.[0]?.outCurrency?.code == "inr" ||
                              (item.currency?.[0]?.outCurrency?.code == "INR" &&
                                "₹")}
                            {(item.currency?.[0]?.outCurrency?.code == process.env.REACT_APP_CURRENCY_CODE ||
                              item.currency?.[0]?.outCurrency?.code ==
                                process.env.REACT_APP_CURRENCY_CODE_OUT) && (
                              <img
                                src={
                                  url.imageUrl +
                                  item.currency?.[0]?.outCurrency?.img?.default
                                }
                                alt=""
                              />
                            )}
                            <span>{item.prizePool}</span>
                          </h5>}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </OwlCarousel>
            </div>
          )}

          {showContacts === true && (
            <div className="tc_popup_bg">
              <div
                className="custom-popup home-featured show"
                id="contact_popup"
              >
                <div className="popup-head bg-light">
                  <h3>{allFeaturedData?.gameName}</h3>
                  <button
                    className="close-popup"
                    onClick={() => setShowContacts(false)}
                  >
                    <img src={Close} alt="" />
                  </button>
                </div>
                <div className="popup-body">
                  <TournamentsStructure allFeaturedData={allFeaturedData} />
                </div>
              </div>
            </div>
          )}
        </Fragment>
      ) : (
        <></>
      )}
    </>
  );
}
export default Featured;
