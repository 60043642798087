import React, { Fragment, useEffect, useState, useRef } from "react";
import Previous from "../assets/images/previous.svg";
import Rule from "../assets/images/rules_eye_icon_blue.png";
import CopyIcon from "../assets/images/copy-icon.svg";
import ReactHtmlParser from "react-html-parser";
// import PaytmIcon from "../assets/images/paytm_icon.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import {
  getContestMyList,
  getGamesDetails,
  getMyContestList,
  getMyEventsList,
  getMyOldContestList,
  getRules,
  tournamentListDetails,
} from "../apis";
import {
  dateFormat,
  goback,
  formatAMPM,
  getTournamentDateTime,
  getProfiledateFormat,
} from "../component/common";
import url from "../constants/url";
import { routehelp } from "../config/routehelp";
import Close from "../assets/images/close.svg";
import NoContests from "../assets/images/Group_8748.png";
import NoTournaments from "../assets/images/Group_8752.png";
import Loader from "../component/loader";
import { handleTextAnimation } from "../component/textAnimation";
import { constants } from "../constants/constants";
import Advertisement from "../component/advertisement";
import { ScrimlistCard } from "../component/ScrimComponent/ScrimlistCard";
import { JoinedScrimCard } from "../component/ScrimComponent/JoinedScrimCard";
import { howToJoinGame } from "../apis";
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie'
import { events } from "../utils/appEvents";
import { eventTracking } from "../firebaseAnalytics";
import { useProfile } from "../component/ProfileComponent/ProfileContext";

function MyContest(props) {
  const [eventList, setEventList] = useState([]);
  const [newContestList, setNewContestList] = useState([]);
  // const [contestList, setContestList] = useState([]);
  const [eventData, setEventData] = useState(null);
  const [contestData, setContestData] = useState(null);
  const [showRules, setShowRules] = useState(false);
  const [showWinners, setShowWinners] = useState(false);
  const [allWinners, setAllWinners] = useState([]);
  const [isSaasContest, setIsSaasContest] = useState([]);
  const [rulesData, setRulesData] = useState("");
  const [loader, setLoader] = useState(false);
  const [limitData, setlimitData] = useState(10);
  const [conteatTotalRecord, setConteatTotalRecord] = useState(0);
  const [limitDataEvent, setlimitDataEvent] = useState(10);
  const [selectedTab, setSelectedTab] = useState(1);
  const [page, setPage] = useState(1);
  const [gameData, setGameData] = useState("all");
  const { t } = useTranslation()
  const currentLang = Cookies.get('i18next') || 'EN'
  const {walletDetails} = useProfile()
  // let game = JSON.parse(localStorage.getItem("Game_Details"));
  // console.log(game?.code.toLowerCase());
  // console.log(gameData.code?.toLowerCase() != 'cgl')

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoader(true);
    let allpage = [...JSON.parse(localStorage.getItem("currentPage"))];
    var count = allpage.findIndex((d) => d === window.location.pathname);
    count === -1 && allpage.push(window.location.pathname);
    localStorage.setItem("currentPage", JSON.stringify(allpage));

    await getContestList(1);
    // await getEventList(limitDataEvent);
  };

  const getContestList = async (page) => {
    setPage(page);
    var user_data = JSON.parse(localStorage.getItem(constants.profileLite));

    var payload = {
      search: { game: localStorage.getItem("activeGameId") },
    };
    payload.search.user = user_data?.item?.user_id;
    payload.search.status = [
      "waiting",
      "started",
      "inprogress",
      "inProcess",
      "review",
      "cancelled",
      "completed",
    ];

    payload.pagination = {
      pageNo: page,
      recordPerPage: limitData,
      sortBy: "dateTime",
      sortDirection: "desc",
    };

    let res = await getMyContestList(payload);
    if (res) {
      setContestData(res);
      // console.log("new contest", res.success);
      if (res.data && res.success) {
        // console.log("new contest", res.data);
        setConteatTotalRecord(res.totalRecord)
        var tempArr = res.data.map((item) => {
          let tempItem = { ...item };
          tempItem.isSaasContest = true;
          return tempItem;
        });
        setNewContestList([...newContestList, ...tempArr]);
      }
      // setContestList(res?.data);
      setLoader(false);
    }

    // getOldContestList(limitData)
  };

  // const getOldContestList = async (limit) => {
  //   var payload = {
  //     game: localStorage.getItem("activeTournamnetId"),
  //     limit: limit,
  //     skip: 0,
  //   };
  //   let res = await getMyOldContestList(payload);
  //   // console.log(("res=> old contest", res));
  //   if (res && res.list) {
  //     setContestData(res);
  //     var tempArr = res.list.map((item) => {
  //       let tempItem = { ...item };
  //       return tempItem;
  //     });
  //     //  setGameTypeList(tempArr)

  //     tempArr.sort((a, b) => {
  //       // addLog(a.dateTime, b.dateTime)
  //       return new Date(b.dateTime) - new Date(a.dateTime);
  //     });

  //     setContestList([...contestList, ...tempArr]);
  //     // setContestList(res?.list);
  //     setLoader(false);
  //   }
  // };
  const getEventList = async (limit) => {
    //{"limit":10,"skip":2,"sortBy":"startDate","sort":"desc","game":"634a42a89633f3f5b7228455","user":"GSGJ001441325"}
    let res1 = await tournamentListDetails({
      game: localStorage.getItem("activeGameId"),
      limit: limit,
      sortBy: "startDate",
      sort: "desc",
      user: walletDetails?.user_id,
    });

    setLoader(false);
    if (res1 && res1.list) {
      setEventList(res1?.list);
      setEventData(res1);
      // console.log(res1?.list)
    }
  };

  const getWinnerClick = (item, e, isSaasContest) => {
    setShowWinners(true);
    setAllWinners(item);

    setIsSaasContest(isSaasContest);
    // console.log(isSaasContest);
    if (e && e.stopPropagation) e.stopPropagation();
  };
  const navigate = useNavigate()
  const getRulesClick = async (item, e) => {
    if (e && e.stopPropagation) e.stopPropagation();

    console.log(item)
    let response = await getRules(item?._id);
    if (response?.success) {
      setRulesData(response?.item?.rules)
      setShowRules(true);
    }
    // setShowRules(true);
    // setRulesData(item?.rules);
  };

  const openContestDetails = (id) => {
    // window.location.href = `/contest-details/${id}`;
   navigate(`/contest-details/${id}`)

  };
  const openOldContestDetails = (id) => {
    window.location.href = `/contestdetails/${id}`;
  };

  const openEventDetails = (id) => {
    // window.location.href = `/tounament-details/${id}`;
   navigate(`/tounament-details/${id}`);

  };

  const getLoadMore = async (type) => {
    setLoader(true);
    if (type === "contest") {
      getContestList(page + 1);
    } else {
      setlimitDataEvent(limitDataEvent + 10);
      await getEventList(limitDataEvent + 10);
    }
  };

  const mounted = useRef();
  // This useEffect() for componentDidUpdate()
  useEffect(() => {
    // // console.log(mounted.current)
    if (!mounted.current) {
      // do componentDidMount logic
      mounted.current = true;
    } else {
      // do componentDidUpdate logic
      handleTextAnimation();
    }
  });
 
  return (
    <div className="inner-wrap" id="my_contest_main_inner">
      <ToastContainer />
      <div className="header">
        <div className="back-btn">
          <Link to={`/tournaments/${localStorage.getItem('activeGameId')}`}>
            <img src={Previous} alt="" />
          </Link>
        </div>
        <h2>{localStorage.getItem("activeGameName")} {t('header.matches')}</h2>

        <div className="wallet-btn">
          {/* <Link to="/">
            <img src={Wallet} alt="" />
            Wallet
          </Link> */}
        </div>
      </div>
      <div
        className="list-group contest-tab main_tabs"
        id="list-tab"
        role="tablist"
      >
        <a
          className="list-group-item list-group-item-action active"
          style={{borderRadius:'0'}}
          id="list-home-list"
          data-toggle="list"
          href="#list-1"
          onClick={() => {
            setSelectedTab(1);
            getContestList(1)
            eventTracking(events.mycontests_contests, {
              EP_GameName:localStorage.getItem("activeGameName"), 
            EP_GameType:JSON.parse(localStorage.getItem("activeGameType"))?.name, 
            EP_GameID:localStorage.getItem("activeGameId"), 
            // EP_ContestID:contest?._id
               });
          }}
        >
          {t('header.contest')}
        </a>
        <a
          className="list-group-item list-group-item-action"
          style={{borderRadius:'0'}}

          id="list-profile-list"
          data-toggle="list"
          href="#list-2"
          onClick={() => {
            setSelectedTab(2);
            setNewContestList([])
            getEventList(limitDataEvent)
            eventTracking(events.mycontests_tournaments, {
              EP_GameName:localStorage.getItem("activeGameName"), 
            EP_GameType:JSON.parse(localStorage.getItem("activeGameType"))?.name, 
            EP_GameID:localStorage.getItem("activeGameId"), 
            // EP_TournamnetID:item?._id
               });
          }}
        >
          {t('header.tournaments')}
        </a>
        {/* {game?.code && game.code.toLowerCase() === "cgl" ? null : (
          <a
            className="list-group-item list-group-item-action"
            id="list-profile-list"
            data-toggle="list"
            href="#list-3"
            onClick={() => {
              setSelectedTab(3);
            }}
          >
            {t('myContest.private')}
          </a>
        )} */}
      </div>

      <div
        className="body mCustomScrollbar _mCS_1 bottom-btn-body mycontest_main pt-0"
        id={`${showRules === true || showWinners === true ? "popup_show" : ""}`}
      >
        <div className="all_sub_screens_div_related_to_ads">
          <div className="tab-content h-100" id="nav-tabContent">
            {selectedTab === 1 && (
              <div
                className={`tab-pane ${selectedTab === 1 && "active"} h-100`}
                id="list-1"
              >
                {loader ?
                  <Loader />
                  : newContestList.length > 0 ?
                    newContestList?.map((item, index) => {
                      var contest = item.contestData;

                      var per =
                        (100 * contest?.totalJoinedPlayers) /
                        contest?.totalPlayers +
                        "%";
                      return (
                        <div
                          className="contest-list contest-tournament-list game_tounament mb-3"
                          key={index}
                        >
                          <p className="status_contest bg-primary">
                            {contest?.status}
                          </p>
                          <div className="box" style={{ cursor: "pointer" }}>
                            <h3 style={{ fontSize: "16px", fontWeight: "800" }}>
                              {
                                contest?.title.length > 32 ?
                                  <marquee>
                                    {contest?.title} ({contest?.gameTypeData[0].name})
                                  </marquee> : <>{contest?.title} ({contest?.gameTypeData[0].name})</>
                              }

                              <span className="pointer">
                                {t('contest_details.id')}: {contest?.shortCode}
                              </span>
                            </h3>
                            <div
                              className="row tournament_contest_row"
                              style={{ marginBottom: "-20px" }}
                            >
                              <div
                                className="col-12"
                                onClick={() => {openContestDetails(contest?._id);

                                 

                                }}
                              >
                                <div className="inner_row">
                                  <div className="inner_item">
                                    {t('contest_details.date')}{" "}
                                    <b>{getProfiledateFormat(contest?.date)}</b>
                                  </div>
                                  <div className="inner_item">
                                    {t('contest_details.time')}{" "}
                                    <b>{formatAMPM(new Date(contest?.time))}</b>
                                  </div>
                                  {contest?.titles?.slice(0, 2).map((item1, index1) => {
                                    if (item1?.name !== "-") {
                                      return (
                                        <div className="inner_item" key={index1}>
                                          {item1?.name} <b className="text-ellipsis">{item1?.value}</b>
                                        </div>
                                      );
                                    }
                                  })}
                                </div>
                                <div className="inner_row bg-dark">
                                  <div className="inner_item inner_item_medium">
                                    {t('contest_details.prize_pool')} <br />{" "}
                                    <b>
                                      <img
                                        src={
                                          url.imageUrl +
                                          contest?.currency?.[0].outCurrency
                                            ?.img?.default
                                        }
                                        alt=""
                                        className="coin_img"
                                      />{" "}
                                      {contest?.prizePool}
                                    </b>
                                  </div>
                                  <div className="inner_item inner_item_medium">
                                    {/* {contest.killPoints === "" ? "Winners" : "Per Kill"}{" "} */}
                                    {contest.winningModel === "perRank"
                                      ? t('tournaments.winners')
                                      : t('tournaments.per_kill')}{" "}
                                    {contest.winningModel === "perRank" ? (
                                      <b
                                        className="pointer"
                                        onClick={(e) =>
                                          getWinnerClick(contest, e, true)
                                        }
                                      >
                                        {" "}
                                        {contest?.totalWinners}{" "}
                                        <i className="fas fa-chevron-down"></i>
                                      </b>
                                    ) : (
                                      <b className="">
                                        <img
                                          src={
                                            url.imageUrl +
                                            contest?.currency?.[0]?.outCurrency
                                              ?.img?.default
                                          }
                                          alt=""
                                          className="coin_img"
                                        />
                                        {" "}
                                        {contest?.killPoints}{" "}
                                      </b>
                                    )}
                                  </div>
                                  <div className="inner_item inner_item_medium">
                                    {t('contest.join_using')} <br />{" "}
                                    {contest?.entryFee > 0 ? (
                                      <b>

                                        <img
                                          src={
                                            url.imageUrl +
                                            contest?.currency?.[0]?.inCurrency
                                              ?.img?.default
                                          }
                                          alt=""
                                          className="coin_img"
                                        />
                                        {" "}
                                        {contest?.entryFee}
                                      </b>
                                    ) : (
                                      <b>{t('contest_details.free')}</b>
                                    )}
                                  </div>
                                </div>
                                <div className="avalible_wrap">
                                  <div className="inner">
                                    <span
                                      style={{
                                        width: per,
                                      }}
                                    ></span>
                                  </div>
                                  <p className="d-flex justify-content-between text-light">
                                    <span className="">
                                      {t('contest_details.players_remaining', {
                                        players: contest?.totalPlayers -
                                          contest?.totalJoinedPlayers
                                      })}
                                    </span>
                                    <span className="">
                                      {t('contest_details.players_joined', { players: contest?.totalJoinedPlayers })}
                                    </span>
                                  </p>
                                </div>
                              </div>

                              <div className="contest_foot contest_details_foot bg-secondary text-light p-2">
                                <div className="inner inner-text pl-4">
                                  <span>{t('contest_details.room_id')}</span>
                                  <b className="d-flex" style={{ fontSize: "12px" }}>
                                    {" "}
                                    {contest?.roomId === ""
                                      ? "-"
                                      :
                                      contest?.roomId?.length > 15 ? <marquee>{contest?.roomId}</marquee>
                                      :
                                      contest?.roomId}{" "}
                                    {contest?.roomId != "" && (
                                      <img
                                        src={CopyIcon}
                                        alt=""
                                        style={{ cursor: "pointer", marginLeft:'0.5rem' }}
                                        onClick={() => {
                                          navigator.clipboard.writeText(
                                            contest?.roomId
                                          );
                                          toast.info(t('info_Copied'), {
                                            position: "top-center",
                                            autoClose: 2000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                          });
                                        }}
                                      />
                                    )}
                                  </b>
                                </div>
                                <div className="inner inner-text pl-4">
                                  <span>{t('contest_details.password')}</span>
                                  <b className="d-flex" style={{ fontSize: "12px" }}>
                                    {" "}
                                    {contest?.roomPassword === ""
                                      ? "-"
                                      : 
                                      contest?.roomPassword.length > 15 ? <marquee>{contest?.roomPassword}</marquee>
                                      :
                                      contest?.roomPassword}{" "}
                                    {contest?.roomPassword != "" && (
                                      <img
                                        src={CopyIcon}
                                        alt=""
                                        style={{ cursor: "pointer" , marginLeft:'0.5rem' }}
                                        onClick={() => {
                                          navigator.clipboard.writeText(
                                            contest?.roomPassword
                                          );
                                          toast.info(t('info_Copied'), {
                                            position: "top-center",
                                            autoClose: 2000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                          });
                                        }}
                                      />
                                    )}
                                  </b>
                                </div>
                                {item?.column > 0 && (
                                  <div className="inner inner-text pl-4">
                                    <span>{t('contest.slot')}</span>
                                    <b
                                      className="d-flex justify-content-center align-items-center"
                                      style={{
                                        width: "20px",
                                        fontSize: "12px",
                                        backgroundColor: "var(--dark)",
                                        borderRadius: "4px",
                                      }}
                                    >
                                      {item?.column}
                                    </b>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                      // }
                    })
                    :
                    (
                      newContestList.length === 0 && (
                        <div className="no_tournament">
                          <img src={NoContests} style={{ height: "50%" }} />
                        </div>
                      )
                    )}
                {conteatTotalRecord > newContestList.length && (
                  <div className="load_more text-center">
                    <button
                      className="btn btn-primary"
                      onClick={() => getLoadMore("contest")}
                    >
                      {loader === true ? `${t('contest.loading')}...` : t('contest.load_more')}
                    </button>
                  </div>
                )}

                {/* {newContestList.length > 10} */}
              </div>
            )}

            {selectedTab === 2 && (
              <div
                className={`tab-pane ${selectedTab === 2 && "active"} h-100`}
                id="list-2"
              >
                {eventList.length > 0 ? (
                  eventList?.map((item, index) => {
                    var per =
                      (100 * item?.totalJoinedPlayers) / item?.totalPlayers +
                      "%";
                    return (
                      <div
                        className="contest-list contest-tournament-list mb-3"
                        id="tournament_main"
                        key={index}
                      >
                        <p className="status_contest bg-primary">
                          {item?.status}
                        </p>
                        <div
                          className="box"
                          onClick={() => {openEventDetails(item?._id);


                          }}
                          style={{
                            cursor: "pointer",
                            marginBottom: "-60px",
                            paddingBottom: "60px",
                          }}
                        >
                          <h3 style={{ fontSize: "16px", fontWeight: "800" }}>
                            {String(item?.title)?.length > 32 ? (
                              <marquee>{item?.title}</marquee>
                            ) : item?.title}

                            <span
                              className="pointer"
                              onClick={(e) => getRulesClick(item, e)}
                            >
                              <img
                                src={Rule}
                                alt=""
                                style={{ height: "15px", marginRight: "3px" }}
                              />{" "}
                              {t('contest_details.rules')}
                            </span>
                          </h3>
                          <div
                            className="row tournament_contest_row"
                            style={{}}
                          >
                            <div className="col-4 pr-1">
                              <div className="img" style={{ height: "100%" }}>
                                <img
                                  src={
                                    url?.imageUrl + item?.featuredImage?.default
                                  }
                                  alt=""
                                  style={{ maxHeight: "135px" }}
                                />
                                <div className="img-caption">
                                  <h5>{item?.gameType?.name}</h5>
                                </div>
                              </div>
                            </div>
                            <div className="col-8">
                              <div className="inner_row pt-0">
                                <div className="inner_item inner_item_medium ">
                                  {t('contest_details.date')}{" "}
                                  <b>
                                    {getProfiledateFormat(item?.startDate)}
                                    {/* to{" "}{dateFormat(item?.endDate)} */}
                                  </b>
                                </div>
                                <div className="inner_item inner_item_medium">
                                  {t('contest_details.rounds')} <b>{item?.rounds?.length}</b>
                                </div>
                                <div className="inner_item inner_item_medium">
                                  {t('contest_details.id')} <b>{item?.shortCode}</b>
                                </div>
                              </div>
                              <div
                                className="inner_row bg-primary"
                                style={currentLang?.toLowerCase() == "ar" ? { borderRadius: "0 15px 15px 0" } : { borderRadius: "15px 0 0 15px" }}
                              >
                                {item?.titles?.slice(0, 2)?.map((item, index) => {
                                  if (item?.name !== "-") {
                                    return (
                                      <div
                                        className="inner_item w-50"
                                        key={index}
                                      >
                                        {item?.name} <b>{item?.value}</b>
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                              <div className="inner_row">
                                <div className="inner_item inner_item_medium">
                                { t('contest_details.prize_pool') }{" "}
                                {item?.prizePool ?
                                  <b>
                                    {/* {item?.currency?.inCurrency?.cType ==
                                      "in" && "₹"} */}
                                    {/* {console.log(
                                      "🚀 ~ file: my-contest.js:845 ~ eventList?.map ~ item?.currency?.code:",
                                      item?.currency?.inCurrency?.code,
                                      item?.currency?.inCurrency?.img?.default
                                    )} */}
                                    {item.currency?.outCurrency?.code ==
                                      "inr" ||
                                      (item.currency?.outCurrency?.code ==
                                        "INR" &&
                                        "₹")}
                                    {(item.currency?.outCurrency?.code == process.env.REACT_APP_CURRENCY_CODE ||
                                      item.currency?.outCurrency?.code == process.env.REACT_APP_CURRENCY_CODE_OUT) && (
                                        <img
                                          src={
                                            url.imageUrl +
                                            item?.currency?.outCurrency?.img
                                              ?.default
                                          }
                                          alt=""
                                          className="coin_img"
                                        />
                                      )}{" "}
                                    {item?.prizePool}
                                  </b>:
                                    <b>
                                     {item?.rewardDisplayText?.length > 14 ? 
                      <marquee>{item?.rewardDisplayText}</marquee> :
                      item?.rewardDisplayText}
                                    </b>
                                  }
                                </div>
                                <div className="inner_item inner_item_medium">
                                  {/* {item.killPoints === "" ? "Winners" : "Per Kill"}{" "}
                                  {
                                    item.killPoints === "" ? */}
                                  {t('tournaments.winners')}
                                  <b
                                    className="pointer"
                                    onClick={(e) =>
                                      getWinnerClick(item, e, false)
                                    }
                                  >
                                    {" "}
                                    {item?.totalWinners}{" "}
                                    <i className="fas fa-chevron-down"></i>
                                  </b>
                                  {/* :
                                      <b className="" >
                                        <img
                                          src={
                                            url.imageUrl +
                                            item?.currency?.img?.default
                                          }
                                          alt=""
                                          className="coin_img"
                                        />
                                        {" "}
                                        {item?.killPoints}{" "}
                                      </b> */}
                                  {/* } */}
                                </div>
                                <div className="inner_item inner_item_medium">
                                  {t('contest.join_using')} <br />{" "}
                                  {item?.entryFee > 0 ? (
                                    <b>
                                      {item?.currency?.inCurrency?.code ==
                                        "INR" && "₹"}
                                      {(item?.currency?.inCurrency?.code ==
                                        process.env.REACT_APP_CURRENCY_CODE||
                                        item?.currency?.inCurrency?.code ==
                                        process.env.REACT_APP_CURRENCY_CODE_OUT) && (
                                          <img
                                            src={
                                              url.imageUrl +
                                              item?.currency?.inCurrency?.img
                                                ?.default
                                            }
                                            alt=""
                                            className="coin_img"
                                          />
                                        )}{" "}
                                      {item?.entryFee}
                                    </b>
                                  ) : (
                                    <b>{t('contest_details.free')}</b>
                                  )}
                                </div>
                              </div>
                              <div className="avalible_wrap">
                                <div className="inner">
                                  <span style={{ width: per }}></span>
                                </div>
                                <p className="d-flex justify-content-between ">
                                  <span className="">
                                    {t('contest_details.players_remaining', {
                                      players: item?.totalPlayers -
                                        item?.totalJoinedPlayers
                                    })}
                                  </span>
                                  <span className="">
                                    {t('contest_details.players_joined', { players: item?.totalJoinedPlayers })}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="contest_foot contest_details_foot bg-secondary text-light"
                          style={{ display: "block", marginBottom: "40px" }}
                        >
                          <p
                            className="text-light text-center py-1 m-0"
                            style={{
                              borderBottom: "1px solid #fff",
                              fontWeight: "600",
                            }}
                          >
                            {/* {item?.contestsList.length-1} */}

                            {item?.contestsList[item?.contestsList.length - 1]
                              ?.title +
                              " - " +
                              dateFormat(
                                item?.contestsList[
                                  item?.contestsList.length - 1
                                ]?.date
                              ) +
                              " " +
                              formatAMPM(
                                new Date(
                                  item?.contestsList[
                                    item?.contestsList.length - 1
                                  ]?.time
                                )
                              )}

                            {/* {"R1M1 - " +
                              dateFormat(item?.startDate) + " "+
                              formatAMPM(new Date(item?.startDate))} */}
                          </p>

                          <div className="d-flex p-2">
                            <div className="inner inner-text pl-4">
                              <span>{t('contest_details.room_id')}</span>
                              <b className="d-flex" style={{ fontSize: "12px" }}>
                                {" "}
                                {item?.contestsList[
                                  item?.contestsList.length - 1
                                ]?.roomId === ""
                                  ? "-"
                                  : 
                                  
                                  item?.contestsList[
                                    item?.contestsList.length - 1
                                  ]?.roomId?.length > 15 ? <marquee>{
                                    item?.contestsList[
                                      item?.contestsList.length - 1
                                    ]?.roomId}</marquee> :
                                  item?.contestsList[
                                    item?.contestsList.length - 1
                                  ]?.roomId}{" "}
                                {item?.contestsList[
                                  item?.contestsList.length - 1
                                ]?.roomId != "" && (
                                    <img
                                      src={CopyIcon}
                                      alt=""
                                      style={{ cursor: "pointer", marginLeft:'0.5rem' }}
                                      onClick={() => {
                                        navigator.clipboard.writeText(
                                          item?.contestsList[
                                            item?.contestsList.length - 1
                                          ]?.roomId
                                        );
                                        toast.info(t('info_Copied'), {
                                          position: "top-center",
                                          autoClose: 2000,
                                          hideProgressBar: false,
                                          closeOnClick: true,
                                          pauseOnHover: true,
                                          draggable: true,
                                          progress: undefined,
                                        });
                                      }}
                                    />
                                  )}
                              </b>
                            </div>
                            <div className="inner inner-text pl-4">
                              <span>{t('contest_details.password')}</span>
                              <b className="d-flex" style={{ fontSize: "12px" }}>
                                {" "}
                                {item?.contestsList[
                                  item?.contestsList.length - 1
                                ]?.roomPassword === ""
                                  ? "-"
                                  : 
                                  item?.contestsList[
                                    item?.contestsList.length - 1
                                  ]?.roomPassword?.length > 15 ? <marquee>{item?.contestsList[
                                    item?.contestsList.length - 1
                                  ]?.roomPassword}</marquee>
                                  :
                                  item?.contestsList[
                                    item?.contestsList.length - 1
                                  ]?.roomPassword}{" "}
                                {item?.contestsList[
                                  item?.contestsList.length - 1
                                ]?.roomPassword != "" && (
                                    <img
                                      src={CopyIcon}
                                      alt=""
                                      style={{ cursor: "pointer" , marginLeft:'0.5rem' }}
                                      onClick={() => {
                                        navigator.clipboard.writeText(
                                          item?.contestsList[
                                            item?.contestsList.length - 1
                                          ]?.roomPassword
                                        );
                                        toast.info(t('info_Copied'), {
                                          position: "top-center",
                                          autoClose: 2000,
                                          hideProgressBar: false,
                                          closeOnClick: true,
                                          pauseOnHover: true,
                                          draggable: true,
                                          progress: undefined,
                                        });
                                      }}
                                    />
                                  )}
                              </b>
                            </div>
                            {item?.contestsList[item?.contestsList.length - 1]
                              ?.column !== "0" && (
                                <div className="inner inner-text pl-4">
                                  <span>{t('tournaments.slot')}</span>
                                  <b
                                    className="d-flex justify-content-center align-items-center"
                                    style={{
                                      width: "20px",
                                      fontSize: "12px",
                                      backgroundColor: "var(--dark)",
                                      borderRadius: "4px",
                                    }}
                                  >
                                    {" "}
                                    {item?.contestsList[
                                      item?.contestsList.length - 1
                                    ]?.column === ""
                                      ? "-"
                                      : item?.contestsList[
                                        item?.contestsList.length - 1
                                      ]?.column}{" "}
                                  </b>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : eventList === null ? (
                  <Loader />
                ) : (
                  // No Tournaments
                  <div className="no_tournament">
                    <img src={NoTournaments} style={{ height: "50%" }} />
                  </div>
                )}
                {eventData?.count > eventList?.length && (
                  <div className="load_more text-center">
                    <button
                      className="btn btn-primary"
                      onClick={() => getLoadMore("event")}
                    >
                      {loader === true ? `${t('contest.loading')}...` : t('tournaments.load_more')}
                    </button>
                  </div>
                )}
              </div>
            )}

            {selectedTab === 3 && (
              <div
                className={`tab-pane ${selectedTab === 3 && "active"} h-100`}
                id="list-2"
              >
                <JoinedScrimCard />
              </div>
            )}

            {showRules === true && (
              <div
                className="custom-popup rules custom_scroll show"
                id="contact_popup"
              >
                <div className="popup-head">
                  <h3>{t('contest_details.rules')}</h3>
                  <button
                    className="close-popup"
                    onClick={() => setShowRules(false)}
                  >
                    <img src={Close} alt="" />
                  </button>
                </div>
                <div className="popup-body bg-yellow" style={{padding:'0 10px 60px 10px'}}>
                  <p>{ReactHtmlParser(rulesData)}</p>
                </div>
              </div>
            )}
            {showWinners === true && (
              <div
                className="custom-popup rules custom_scroll show"
                id="contact_popup"
              >
                <div className="popup-head">
                  <h3>{t('contest_details.winning_breakup')}</h3>
                  <button
                    className="close-popup"
                    onClick={() => setShowWinners(false)}
                  >
                    <img src={Close} alt="" />
                  </button>
                </div>
                <div className="popup-body bg-yellow">
                  <div className="all_sub_screens_div_related_to_ads">
                    {/* {console.log(
                      "🚀 ~ file: my-contest.js:1046 ~ allWinners:",
                      allWinners
                    )} */}
                    {allWinners?.pointpool &&
                      allWinners?.pointpool?.length !== 0 && (
                        <div
                          className="list-group main-tab w-90"
                          id="list-tab"
                          role="tablist"
                        >
                          <a
                            className="list-group-item list-group-item-action active"
                            id="list-home-list"
                            data-toggle="list"
                            href="#prize-1"
                          >
                            {t('contest_details.prize_pool')}
                          </a>
                          <a
                            className="list-group-item list-group-item-action"
                            id="list-profile-list"
                            data-toggle="list"
                            href="#point-2"
                          >
                            {t('contest_details.points')}
                          </a>
                        </div>
                      )}

                    <div className="tab-content" id="nav-tabContent">
                      <div className="tab-pane active" id="prize-1">
                        <div className="prize_pool mb-3 text-center p-2 w-90">
                          <p>{t('contest_details.prize_pool')}</p>
                          <h6>
                            {isSaasContest ? (
                              <>
                                <img
                                  src={
                                    url.imageUrl +
                                    allWinners?.currency?.[0].outCurrency
                                      ?.img?.default
                                  }
                                  alt=""
                                  className="coin_img_big"
                                />
                              </>
                            ) : (
                              <>
                                {/* {console.log("selectedTab", selectedTab)} */}
                                {selectedTab == 1 ? (
                                  <>
                                    <img
                                      src={
                                        url.imageUrl +
                                        allWinners?.currency?.inCurrency?.img
                                          ?.default
                                      }
                                      alt=""
                                      className="coin_img"
                                    />
                                  </>
                                ) : (
                                  <>
                                  {
                                    !allWinners?.rewardDisplayText && 
                                    <img
                                    src={
                                      url.imageUrl +
                                      allWinners?.currency?.outCurrency?.img
                                        ?.default
                                    }
                                    alt=""
                                    className="coin_img"
                                    style={currentLang?.toLowerCase() == "ar" ? {
                                      width: "19px",
                                      height: "18px",
                                      marginLeft: "5px",
                                    } : {
                                      width: "19px",
                                      height: "18px",
                                      marginRight: "5px",
                                    }}
                                  />
                                  }
                                   
                                  </>
                                )}{" "}
                              </>
                            )}
                            {" "}
                            {/* {console.log("*****************",allWinners)} */}
                            {allWinners?.rewardDisplayText ||  allWinners?.prizePool}
                          </h6>
                        </div>
                        <div className="table-responsive total_table total_table_border mb-3 w-90">
                          <table>
                            <tbody>
                              {isSaasContest
                                ? allWinners?.prizePoolInfo?.map(
                                  (item1, index1) => {
                                    return (
                                      <tr key={index1}>
                                        <td className={currentLang?.toLowerCase() == "ar" ? "text-left" : ""}>
                                          {t('contest_details.rank')}:{" "}
                                          {item1?.rankTo !== "0"
                                            ? item1?.rankFrom +
                                            "-" +
                                            item1?.rankTo
                                            : item1?.rankFrom}
                                        </td>
                                        <th className={currentLang?.toLowerCase() == "ar" ? "" : "text-right"}>

                                          <img
                                            src={
                                              url.imageUrl +
                                              allWinners?.currency?.[0]?.outCurrency?.img
                                                ?.default
                                            }
                                            alt=""
                                            className="coin_img"
                                          />
                                          {" "}
                                          {item1?.amount || item1?.prize}
                                        </th>
                                      </tr>
                                    );
                                  }
                                )
                                : allWinners?.prizePoolInfo?.map(
                                  (item1, index1) => {
                                    return (
                                     
                                      <tr key={index1}>
                                         {console.log("*************",item1)}
                                        <td className={currentLang?.toLowerCase() == "ar" ? "text-left" : ""}>
                                          {t('contest_details.rank')}:{" "}
                                          {item1?.rankTo !== "0"
                                            ? item1?.rankFrom +
                                            "-" +
                                            item1?.rankTo
                                            : item1?.rankFrom}
                                        </td>
                                        <th className={currentLang?.toLowerCase() == "ar" ? "" : "text-right"}>
                                        {
                                          item1?.totalAmount && <img
                                          src={
                                            url.imageUrl +
                                            allWinners?.currency
                                              ?.outCurrency?.img?.default
                                          }
                                          alt=""
                                          className="coin_img"
                                        />
                                        }
                                          
                                          {" "}
                                          {item1?.totalAmount || item1?.prize}
                                        </th>
                                      </tr>
                                    );
                                  }
                                )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {allWinners?.pointpool && (
                        <div className="tab-pane" id="point-2">
                          <div className="table-responsive total_table total_table_border mb-3 w-90">
                            <table>
                              <tbody>
                                {allWinners?.pointpool?.map((item1, index1) => {
                                  return (
                                    <tr key={index1}>
                                      <td className={currentLang?.toLowerCase() == "ar" ? "text-left" : ""}>
                                        {t('contest_details.rank')}:{" "}
                                        {item1?.rankTo !== "0"
                                          ? item1?.rankFrom +
                                          "-" +
                                          item1?.rankTo
                                          : item1?.rankFrom}
                                      </td>
                                      <th className={currentLang?.toLowerCase() == "ar" ? "" : "text-right"}>
                                        {item1?.point }
                                      </th>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {
                    !JSON.parse(localStorage.getItem("premiumUser")) &&

                    <Advertisement
                      screen={
                        localStorage.getItem("apk_screens_list") === null
                          ? { code: "no_results" }
                          : JSON.parse(localStorage.getItem("apk_screens_list"))
                            .prizePoolPopup
                      }
                      screen_div_class_name=".all_sub_screens_div_related_to_ads"
                      screen_type="sub"
                    />
                  }
                </div>
              </div>
            )}
          </div>
        </div>
        {
          !JSON.parse(localStorage.getItem("premiumUser")) &&

          <Advertisement
            screen={
              localStorage.getItem("apk_screens_list") === null
                ? { code: "no_results" }
                : JSON.parse(localStorage.getItem("apk_screens_list")).myContests
            }
            screen_div_class_name=".all_sub_screens_div_related_to_ads"
            screen_type="sub"
          />
        }
      </div>
      {/* <div className="bottom_btn">
        <button className="btn btn-dark main-btn">
          OPEN PUBG APP <img src={RightArrow} alt="" className="mCS_img_loaded" />
        </button>
      </div> */}
    </div>
  );
}

export default MyContest;
